import { Footer, Header, MiniHeader, theme } from '@zappyrent/hundop';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { upperCase, upperFirst } from 'lodash';
import React from 'react';
import { useCookies } from 'react-cookie';
import { ThemeProvider } from 'styled-components';

import useUtmQueryString from '../../hooks/useUtmQueryString';
import brandLogo from '../../images/brandLogo.png';
import {
  cities,
  getListingUri,
  properties,
  vatNumber,
  referralContacts,
} from '../../lib/config';
import { dataLayerPush } from '../../services/tracking';
import { GlobalStyles, StyledContainer } from './Layout.styles';
import { ILayoutProps } from './Layout.types';

const authCookie = '8egma2jfs9mu';
const filterFooterProps = (obj, search: string) => {
  const { content } = obj;
  return { ...obj, content: content.filter(el => el.key !== search) };
};
const filterHeaderProps = (obj, search: string) => {
  const { primaryNav } = obj;
  return { ...obj, primaryNav: primaryNav.filter(el => el.key !== search) };
};

const Layout = ({
  children,
  customHeader,
  customFooter,
  footerRemoveColumn,
  headerRemoveColumn,
  miniHeader = false,
  superHeader,
  user = 'landlord',
  isReferralLayout = false,
}: ILayoutProps): JSX.Element => {
  const { language } = React.useContext(I18nextContext);
  const { t } = useTranslation([
    'a11y',
    'form',
    'geo',
    'miscellaneous',
    'navigation',
    'product',
    'routes',
  ]);
  const [cookies] = useCookies([authCookie]);
  const referralUri = encodeURIComponent(
    process.env.GATSBY_SITE_URL + '/' + language,
  );
  const utmQueryString = useUtmQueryString('referral');
  const referALandlordLandingUrl = `${process.env.GATSBY_SITE_URL}/${language}/refer-a-landlord`;
  const headerProps = {
    ariaLabels: {
      back: t('a11y:back'),
      toggleMenu: t('a11y:toggleMenu'),
      toggleNavMenu: t('a11y:toggleNavMenu'),
    },
    isLoggedIn: cookies[authCookie] ?? false,
    language,
    languages: [
      {
        text: 'IT',
        title: 'Zappyrent in Italiano',
        url: '/it',
      },
      {
        text: 'EN',
        title: 'Zappyrent in English',
        url: '/en',
      },
    ],
    primaryNav: [
      {
        text: t('product:tenant'),
        title: t('product:tenant'),
        key: 'tenant',
        url: '#',
        child: {
          title: t('navigation:ourRentals'),
          body: [
            ...cities.map(city => {
              const cityLabel = t(`geo:${city}`);
              const titleCityLabel = upperFirst(cityLabel);

              return {
                text: titleCityLabel,
                title: ` ${t('product:apartmentsForRent')} ${t(
                  'product:atLocation',
                )} ${titleCityLabel}`,
                url: getListingUri(language, cityLabel),
                child: {
                  title: t('product:whatDoYouNeed'),
                  body: properties
                    /* TODO remove the following filter when DB types
                  will be in sync with listing types */
                    .filter(property => property.key !== 'entireProperty')
                    .map(property => {
                      const propertyLabel = t(`product:${property.key}`);
                      const slugLabel = t(
                        `navigation:slug${upperFirst(property.key)}`,
                      );

                      return {
                        text: propertyLabel,
                        title: `${propertyLabel} ${t(
                          'product:atLocation',
                        )} ${titleCityLabel}`,
                        url: getListingUri(language, cityLabel, slugLabel),
                      };
                    }),
                },
              };
            }),
            {
              text: language === 'it' ? 'Altre città' : 'Other cities',
            },
          ],
        },
      },
      {
        text: t('product:landlord'),
        title: t('product:landlord'),
        key: 'landlord',
        url: '#',
        child: {
          body: [
            {
              text: t('navigation:publishAListing'),
              title: t('navigation:publishAListing'),
              url: `${process.env.GATSBY_SITE_URL}/it/pubblica-annuncio-gratis`,
            },
            {
              text: t('navigation:howToRentAHome'),
              title: t('navigation:howToRentAHome'),
              url: `${process.env.GATSBY_SITE_URL}/it/come-affittare-casa`,
            },
            {
              text: t('product:protezioneZappyrent'),
              title: t('product:protezioneZappyrent'),
              url: `${process.env.GATSBY_SITE_URL}/it/protezione-zappyrent`,
            },
            {
              text: t('navigation:rentsBlog'),
              title: t('navigation:rentsBlog'),
              url: `${process.env.GATSBY_SITE_URL}/it/blog`,
            },
          ],
        },
      },
      // {
      //   text: t('product:rentCalculator'),
      //   title: t('product:rentCalculator'),
      //   key: 'rentCalculator',
      //   url: `${process.env.GATSBY_SITE_URL}/${language}/${kebabCase(
      //     t('product:rentCalculator'),
      //   )}`,
      // },
      {
        text: t('product:inviteALandlord'),
        title: t('product:inviteALandlord'),
        key: 'inviteALandlord',
        url: referALandlordLandingUrl,
      },
      {
        text: t('product:CalculateRent'),
        title: t('product:CalculateRent'),
        key: 'rentCalculator',
        url: `${process.env.GATSBY_SITE_URL}/${language}/${t(
          'routes:rentCalculator',
        )}`,
        color: theme.colors.landlord.main,
      },
    ],
    secondaryNav: [
      {
        text: t('form:signIn'),
        title: t('form:signIn'),
        url: `${process.env.GATSBY_SITE_URL}/auth/login?from=${referralUri}`,
      },
      {
        text: t('navigation:signUp'),
        title: t('navigation:signUp'),
        url: `${process.env.GATSBY_SITE_URL}/auth/register?from=${referralUri}`,
      },
      {
        text: t('navigation:myProfile'),
        title: t('navigation:myProfile'),
        url: `${process.env.GATSBY_SITE_URL}/${language}/${t(
          'navigation:profilePageSlug',
        )}`,
      },
    ],
    user,
  };
  const miniHeaderProps = {
    ctaLabel: t('product:needHelp'),
    onCtaClick: () => {
      dataLayerPush({
        event: 'asyncAction',
        gaHitType: 'event',
        category: 'landing',
        action: 'click_cta_phone',
      });
    },
    user,
  };
  const footerProps = {
    content: [
      {
        title: 'ZAPPYRENT',
        body: [
          {
            text: t('navigation:whoWeAre'),
            title: t('navigation:whoWeAre'),
            url: `${process.env.GATSBY_SITE_URL}/${language}/who/we/are`,
          },
          {
            text: t('navigation:workWithUs'),
            title: t('navigation:workWithUs'),
            url: `${process.env.GATSBY_SITE_URL}/${language}/careers`,
          },
          // {
          //   text: t('navigation:pressRoom'),
          //   title: t('navigation:pressRoom'),
          //   url: `${process.env.GATSBY_SITE_URL}/it/press`,
          // },
        ],
        key: 'main',
      },
      {
        title: upperCase(t('product:tenant')),
        body: [
          {
            text: t('navigation:tenantsFAQ'),
            title: t('navigation:tenantsFAQ'),
            url: t('navigation:tenantsFAQLink'),
          },
          {
            text: t('navigation:partnerLink'),
            title: t('navigation:partnerLink'),
            url: 'https://try.zappyrent.com/partnership/',
          },
        ],
        key: 'tenant',
      },
      {
        title: upperCase(t('product:landlord')),
        body: [
          {
            text: t('navigation:publishAListing'),
            title: t('navigation:publishAListing'),
            url: `${process.env.GATSBY_SITE_URL}/it/pubblica-annuncio-gratis`,
          },
          {
            text: t('navigation:howToRentAHome'),
            title: t('navigation:howToRentAHome'),
            url: `${process.env.GATSBY_SITE_URL}/it/come-affittare-casa`,
          },
          {
            text: t('navigation:landlordsFAQ'),
            title: t('navigation:landlordsFAQ'),
            url: t('navigation:landlordsFAQLink'),
          },
          {
            text: t('product:protezioneZappyrent'),
            title: t('product:protezioneZappyrent'),
            url: `${process.env.GATSBY_SITE_URL}/it/protezione-zappyrent`,
          },
          {
            text: t('navigation:rentsBlog'),
            title: t('navigation:rentsBlog'),
            url: `${process.env.GATSBY_SITE_URL}/it/blog`,
          },
        ],
        key: 'landlord',
      },
      {
        title: 'REFERRER',
        body: [
          {
            text: t('product:inviteALandlord'),
            title: t('product:inviteALandlord'),
            url: referALandlordLandingUrl,
          },
          {
            text: t('navigation:myReferrals'),
            title: t('navigation:myReferrals'),
            url: `${process.env.GATSBY_REFERRAL_PROGRAM_URL}/${language}?${utmQueryString}`,
          },
          {
            text: t('navigation:referralsFaq'),
            title: t('navigation:referralsFaq'),
            url: `${referALandlordLandingUrl}#faq`,
          },
          {
            text: t('navigation:termsAndConditions'),
            title: t('navigation:termsAndConditions'),
            url: t('navigation:referralProgramTermsAndConditionsLink'),
          },
        ],
        key: 'referrer',
      },
    ],
    cookiePolicyLink: `${process.env.GATSBY_SITE_URL}/${language}/privacy/cookie`,
    //companyInformationsLink: t('navigation:companyInformationsLink'),
    termsAndConditionsLink: `${process.env.GATSBY_SITE_URL}/${language}/terms/conditions`,
    privacyPolicyLink: `${process.env.GATSBY_SITE_URL}/${language}/privacy/policy`,
    labels: {
      emailAddress: isReferralLayout ? referralContacts.email : null,
      contactUs: upperCase(t('navigation:contactUs')),
      //companyInformations: t('navigation:companyInformations'),
      phoneNumber: isReferralLayout ? referralContacts.phone : null,
      termsAndConditions: t('navigation:termsAndConditions'),
      vat: t('miscellaneous:vat'),
      vatNumber,
    },
    contactUsLinks: [
      {
        href: `${process.env.GATSBY_SITE_URL}/${language}/help`,
        text: t('navigation:requestForHelp'),
      },
    ],
  };
  const filteredFooterProps = footerRemoveColumn
    ? filterFooterProps(footerProps, footerRemoveColumn)
    : footerProps;
  const filteredHeaderProps = headerRemoveColumn
    ? filterHeaderProps(headerProps, headerRemoveColumn)
    : headerProps;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <StyledContainer>
        {superHeader}
        {customHeader ? (
          customHeader
        ) : miniHeader ? (
          <MiniHeader {...miniHeaderProps} />
        ) : (
          <Header {...filteredHeaderProps} />
        )}
        <main>{children}</main>
        {customFooter ? (
          customFooter
        ) : (
          <Footer {...filteredFooterProps} brandLogoSrc={brandLogo} />
        )}
      </StyledContainer>
    </ThemeProvider>
  );
};

export default Layout;
